// Height
.min-h-box {
  min-height: 130px !important;
}

// Width
.min-w-80 {
  min-width: 80px !important;
}
.min-w-110 {
  min-width: 110px !important;
}
.min-w-150 {
  min-width: 150px !important;
}
.min-w-170 {
  min-width: 170px !important;
}
.min-w-180 {
  min-width: 180px !important;
}
.min-w-220 {
  min-width: 220px !important;
}
.min-w-270 {
  min-width: 270px !important;
}
.min-w-500 {
  min-width: 500px !important;
}

.container-fluid {
  @extend .px-5;
}
