.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: var(--mdc-theme-primary);
  --mdc-chip-elevated-disabled-container-color: var(--mdc-theme-primary);
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
