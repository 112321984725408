.key-table-fixed {
  font-size: 14px;
  line-height: 11px;
}

.key-table-fixed a {
  color: white;
  font-style: oblique;
  text-decoration: white;
  text-decoration: underline;
}
.key-table-fixed a:hover {
  color: #2ebabe;
}
.key-table-fixed a:focus {
  color: white;
}
.cdk-overlay-container {
  z-index: 2000;
}
.cdk-overlay-pane {
  width: unset !important;
  font-size: 14px;
}

.mat-option-text {
  font-family: 'Titillium Web', sans-serif !important;
}

.mat-sort-header-button {
  padding-left: 0 !important;
}

#table-pos-details {
  width: fit-content;
  min-width: 100% !important;
}
.mat-menu-item {
  font-size: 14px !important;
  max-height: 40px;
  line-height: 40px !important;
}

.mat-chip:not(.mat-basic-chip) {
  background-color: #f3c238;
  color: #fff;
  padding: 3px 5px !important;
  font-size: 11px;
}
.details-box {
  max-height: 80px !important;
  padding-top: 10px;
}
.mat-step-icon {
  background-color: #355d90 !important;
}

#operation-details-accordion span.mat-expansion-indicator {
  -webkit-transform: rotate(0deg) !important;
  -moz-transform: rotate(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  -o-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}

#operation-details-accordion .mat-expansion-panel-body {
  background-color: whitesmoke;
}

#operation-details-accordion.mat-expanded span.mat-expansion-indicator {
  transform: rotate(180deg) !important;
}

.bluebox {
  @extend .col, .text-white, .px-3, .py-4, .bg-light-secondary;
  font-size: 13px;

  &:nth-child(odd) {
    @extend .bg-secondary;
  }

  p:last-child {
    margin-bottom: 0px !important;
  }
}
