// .btn-custom {
//   border-radius: 20px;
//   border: 0;
//   background-color: #355d90;
//   color: #fcfcfc;
//   font-size: 12px;
//   padding-left: 28px;
//   padding-right: 28px;
//   font-family: 'Titillium Web', sans-serif;
//   width: 160px;
//   height: 34px;
// }

// .btn-fixed {
//   width: 70px !important;
//   height: 22px !important;
// }

.icon-button {
  font-size: 13px;
  padding-left: 11px;
  padding-right: 11px;
  width: 34px;
}

// .btn-custom:active,
// .btn-custom:hover {
//   background-color: #2ebabe !important;
//   outline: none !important;
// }

// .btn-custom:focus {
//   outline: none !important;
// }

// .btn-product {
//   border-radius: 20px !important;
//   border: 0;
//   background-color: #355d90 !important;
//   color: #fcfcfc !important;
//   font-size: 12px !important;
//   font-family: 'Titillium Web', sans-serif;
//   width: 90px;
//   height: 34px;
// }
// .btn-product:active,
// .btn-product:hover {
//   background-color: #2ebabe !important;
//   outline: none !important;
// }
// .btn-product:focus {
//   outline: none !important;
// }

button:not(app-configuration-menu button):not(mat-paginator button):not(.header-menu button):not(.mat-calendar-content button):not(
    mat-datepicker-toggle button
  ):not(button.mat-mdc-chip-action):not(.mat-mdc-menu-content button):not(.btn-danger):not(.mdc-switch) {
  font-size: 12px;
  @extend .btn, .btn-light-secondary, .rounded-pill;
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);

  & + button {
    @extend .ms-3;
  }
}

button.btn-sm {
  @extend .px-3, .py-1;
}
table button:not(.mat-calendar-content button):not(button.btn-icon) {
  @extend .btn-sm;
}

button.btn-icon {
  width: 32px;
  @extend .px-0;
  padding: 0.375rem 0 !important;
}

mat-datepicker-toggle .mat-mdc-button-base {
  background: $secondary !important;
  color: white !important;
  width: 30px !important;
  height: 30px !important;
  padding: 0 !important;

  &:hover {
    background: $primary !important;
  }

  svg {
    height: 18px !important;
  }
}
