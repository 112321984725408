// Variables
$primary: #2ebabe;
$secondary: #14437e;
$light-secondary: #355d90;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'light-secondary': $light-secondary,
  'danger': rgb(220, 53, 69),
);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import 'sass/vars/palette.scss';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bridget-fe-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$bridget-fe-accent: mat.m2-define-palette($primary-palette, 300);

// The warn palette is optional (defaults to red).
$bridget-fe-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bridget-fe-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $bridget-fe-primary,
      accent: $bridget-fe-accent,
      warn: $bridget-fe-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bridget-fe-theme);

:root {
  --mdc-theme-primary: #2ebabe;
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0);
  --mdc-typography-body2-font-family: 'Titillium Web';
}

// Var overrides
@import './sass/vars/buttons.scss';

@import '../node_modules/bootstrap/scss/bootstrap.scss';

html,
body {
  height: 100%;
  --mat-form-field-container-height: 30px;
}

// Style overrides
@import './sass/styles/fonts.scss';
@import './sass/styles/form-elements.scss';
@import './sass/styles/mar-pad.scss';
@import './sass/styles/dimensions.scss';
@import './sass/styles/filters.scss';
@import './sass/styles/buttons.scss';
@import './sass/styles/icons.scss';
@import './sass/styles/tables.scss';
@import './sass/styles/paginator.scss';
@import './sass/styles/details.scss';
@import './sass/styles/modals.scss';
@import './sass/styles/chips.scss';
