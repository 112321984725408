// Email
.email-custom {
  border-left: 1px solid #355d90;
  padding-left: 10px;
  border-right: 1px solid #355d90;
  padding-right: 10px;
  font-family: 'Titillium Web', sans-serif;
  color: #355d90;
  font-size: 13px;
  margin-right: 25px !important;
  margin-left: 25px !important;
}

// Checkbox
.mat-checkbox-frame {
  @extend .border-primary;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  @extend .bg-primary;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0 !important;
}

// Checkbox inside mat-select
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background: #2eb9be !important;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after {
  color: black;
}

// Input
.no-focus:focus {
  outline: none !important;
}

.sea-custom {
  border-radius: 20px !important;
  font-size: 12px !important;
  padding-left: 23px !important;
  padding-right: 23px !important;
  width: 200px;
  text-align: center;
  border: 2px solid #2ebabe;
}

.sea-icon {
  float: right !important;
  margin-right: -25px !important;
  margin-top: -26px !important;
  position: relative !important;
  font-size: 14px !important;
  color: #2ebabe;
}

input::-webkit-input-placeholder {
  font-size: 10px;
  color: #2ebabe;
}

.mat-focused .mat-form-field-label {
  color: #355d90;
}

.mat-form-field-underline {
  background-color: #2ebabe;
}

#mat-expansion-panel-header-0 {
  height: 40px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #355d90;
}

.mat-form-field-ripple {
  background-color: #2ebabe;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
  margin: 0.5rem 0;
  padding: 0.25rem 0.5rem;
}

.mat-mdc-form-field-infix:not(.mat-mdc-paginator-container .mat-mdc-form-field-infix) {
  padding-top: 3px !important;
  padding-bottom: 0 !important;
  min-height: 30px;

  span {
    font-size: 13px;
  }
}

::placeholder {
  font-size: 13px !important;
}
app-brandon-autocomplete {
  font-size: 13px;
  position: relative;
  top: -2.5px;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 16px;
}
