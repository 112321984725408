.filter-box {
  @extend .border-2, .border-primary, .border-bottom, .pb-4, .mb-4;

  .col {
    max-width: 330px;
  }
}

.filt-custom {
  background-color: #2ebabe !important;
  max-height: 35px;
}

.filters-row {
  font-size: 12px !important;
  padding-top: 15px;
}

.mat-paginator-icon {
  border-radius: 20px !important;
  background-color: #2ebabe !important;
  color: white;
}

mat-form-field {
  width: 100%;
}

.panel {
  background-color: transparent;
}
.black-panel {
  height: 100vh;
  width: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 1000;
  top: 0;
}

.filter-position {
  position: fixed !important;
  bottom: 0;
  width: 100%;
  z-index: 3;
}
.filter-title {
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
  font-size: 20px !important;
}

mat-expansion-panel span.mat-expansion-indicator {
  transform: rotate(180deg) !important;
  border: 2px solid #fff;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 5px;
  border-radius: 50px;
}

mat-expansion-panel.mat-expanded span.mat-expansion-indicator {
  transform: rotate(0deg) !important;
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
  color: #fff;
}

button.mat-paginator-navigation-previous.mat-icon-button,
button.mat-paginator-navigation-next.mat-icon-button {
  background-color: transparent !important;
}
.mat-select-arrow:active,
.mat-select-arrow:hover,
.mat-select-arrow:visited {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-chip {
  background-color: #2ebabe !important;
  font-size: 13px !important;
}
