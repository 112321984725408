// ---------- Margin ----------

// Global

// X
.margin-right-left-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

// Y

// Top
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-top-75 {
  margin-top: 75px !important;
}

// Right
.margin-right-6 {
  margin-right: 6px !important;
}
.margin-right-38 {
  margin-right: 38px !important;
}

// Bottom
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}

// Left
.margin-left--280 {
  margin-left: -280px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}

// Custom
.margin-user {
  margin-top: 20px;
  margin-bottom: 60px;
}

// ---------- Padding ----------

// Global

// X

// Y

// Top
.padding-top-6 {
  padding-top: 6px;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-15 {
  padding-top: 15px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-top-45 {
  padding-top: 45px;
}
.padding-top-60 {
  padding-top: 60px;
}

// Right
.padding-right-5 {
  padding-right: 5px;
}

// Bottom
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-bottom-50 {
  padding-bottom: 50px;
}

// Left
.padding-left-5 {
  padding-left: 5px;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
}
.padding-left-50 {
  padding-left: 50px;
}
.padding-left-100 {
  padding-left: 100px;
}

// Custom
.h-padding {
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 10px;
}
.h-padding-box {
  padding-top: 15px;
  padding-bottom: 10px;
}
.padding-single-po-list-details-row-button {
  padding-bottom: 20px;
  padding-top: 20px;
  margin-top: 8px;
}
