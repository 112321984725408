.table-container {
  height: 100vh;
  //max-height: 100%;
  overflow: auto;
}

.mat-row.ng-star-inserted:nth-child(2n) {
  background-color: #efefef;
  border-bottom: 1px solid transparent;
}

.mat-row.ng-star-inserted:nth-child(2n):hover,
.mat-row.ng-star-inserted:hover {
  background-color: #b6c5d5;
  border-bottom: 1px solid transparent;
}

.mat-row.ng-star-inserted {
  background-color: white;
}

table:not(.multirow) mat-row:hover td {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.mat-table {
  min-width: fit-content;
}

.mat-header-cell {
  font-weight: bold !important;
}

.container-fluid table:not(.multirow) > tbody > mat-row:nth-of-type(odd) > * {
  --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
  color: var(--bs-table-striped-color);

  &.mat-mdc-table-sticky {
    --bs-table-accent-bg: #f4f4f4;
  }
}

.container-fluid table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem !important;
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  // line-height: 1;
  min-width: 100px;

  &.mat-mdc-table-sticky {
    background-color: white;
  }
}

.container-fluid table.multirow > tbody > mat-row:nth-of-type(4n + 1) > * {
  --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
  color: var(--bs-table-striped-color);
}

mat-row td {
  // line-height: 0;
}

mat-header-row,
mat-row {
  font-size: 14px;
}
mat-header-row {
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
}

.container-fluid table {
  //display: table;

  p {
    margin-bottom: 0 !important;
  }
  mat-header-row,
  mat-row {
    display: table-row !important;
  }

  th,
  td {
    display: table-cell !important;
  }
}

.header-secondary {
  th {
    background: var(--bs-secondary) !important;
    color: white !important;
  }
}
