.error-title {
  color: red;
  text-align: center;
  z-index: 1000;
}

.error-actions {
  width: 100%;
  text-align: center;
}
