body,
.font-Titillium,
.mat-slide-toggle-content,
.mat-input-element {
  font-family: 'Titillium Web', sans-serif !important;
}

.fs-13 {
  font-size: 13px;
}

.title-custom {
  @extend .pb-2, .fst-italic, .text-light-secondary, .border-2, .border-bottom, .border-primary;
}

.mat-dialog-title {
  text-align: center;
  background-color: #355d90;
  color: white;
  padding: 16px;
  margin-bottom: 0;
}
